import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Person2Icon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import './Header.css';
import logo from './logo2.svg';

export default function Header() {


  return (
    <div className="header">
      <div className="header-row">
        <img src={logo} className="header-logo" alt="logo" width="60px" height="40px" />
        <h1 className="header-title">ORTHOSHIELD</h1>
        <div className="header-right">
          {/* <a href="/dashboard" className="header-link">Dashboard</a>
          <a href="/upload" className="header-link">Upload</a> */}
          <Select
            style={{ color: 'white' }}
            value={10}
            renderValue={() => (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                <Person2Icon />
              </div>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  border: 'none',
                  boxShadow: 'none',
                  borderRadius: 'none',
                  marginTop: '20px',
                  width: '200px',
                  border: 'none',
                },
              },
            }}
          >
            <MenuItem value={10}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                cursor: 'pointer',

              }}
            >
              <h3>{localStorage.getItem('username')}</h3>
            </MenuItem>
            <hr style={{ width: '100%', color: 'grey' }} />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '100%',
              cursor: 'pointer',
              backgroundColor: 'white',
              color: 'black',
              padding: '2px',
              borderRadius: '2px',
              height: '25px'
            }}
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                window.location.href = '/login';
              }}
            >
              <LogoutIcon />
              <h5>Logout</h5>
            </div>

          </Select>
        </div>
      </div>
    </div>
  );
}
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#e5465b',
      },
      secondary: {
        main: '#4a4afa',
      },
      logoBackground: {
        main: '#2e3f53',
      }
    },
});

const loginTheme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
        marginBottom: '20px'
      }
    },
    MuiInput: {
      root: {
        width: '100%',
        marginBottom: '20px'
      }
    }
  }
});

export default theme;
export {
  loginTheme
};
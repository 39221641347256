import * as React from 'react';
import { useParams } from 'react-router-dom';


import { Buffer } from 'buffer';

import {
    Box,
    CircularProgress,
    Select,
    Slider,
    Typography,
    MenuItem
} from '@mui/material';


import {
    VisibilityOff as VisibilityOffIcon,
    Visibility as VisibilityIcon
} from '@mui/icons-material';

import { ColorPicker } from 'material-ui-color';
import { PNG } from 'pngjs/browser';

import { serverURL } from "./config";
import './Viewer.css';

import axios from 'axios';

import { useNavigate } from 'react-router-dom';

import Header from './Header';

const rgbToHex = (rgb) => {
    return '#' + rgb.map(x => {
        return ('0' + x.toString(16)).slice(-2);
    }
    ).join('');
}

const VisibilityOnOff = (props) => {
    const [visible, setVisible] = React.useState(props.visible);

    React.useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    if (visible) {
        return <VisibilityIcon
            key={props.key}
            onClick={
                () => {
                    setVisible(false);
                    props.onVisibilityChange(props.name, false);
                }
            } />
    } else {
        return <VisibilityOffIcon
            key={props.key}
            onClick={
                () => {
                    setVisible(true);
                    props.onVisibilityChange(props.name, true);
                }
            } />
    }
}


export default function Viewer(props) {

    const { record_id } = useParams();
    const [fetched, setFetched] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [record, setRecord] = React.useState({});
    const [loggedIn, setLoggedIn] = React.useState(true);
    const [imgSrc, setImgSrc] = React.useState('');
    const [predictions, setPredictions] = React.useState([]);

    const navigate = useNavigate();

    // check if logged in
    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, []);

    React.useEffect(() => {
        const token = localStorage.getItem('token');
        // get record data
        axios.get(`${serverURL}/api/v1/record/${record_id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                setRecord(res.data);
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.removeItem('token');
                    setLoggedIn(false);
                }
            });

    }, [serverURL]);

    React.useEffect(() => {

        const fetchImages = async () => {
            let imageURL = `${serverURL}/api/v1/view/${record_id}`;
            let response = await fetch(`${imageURL}`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
            // blob data
            setImgSrc(URL.createObjectURL(await response.blob()));

            let labelURL = `${serverURL}/api/v1/view_label/${record_id}`;
            response = await fetch(`${labelURL}`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
            // text data
            let body = await response.text();
            let lines = body.split('\n');
            let predictions = [];
            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                if (line.length > 0) {
                    let parts = line.split(' ');
                    let prediction = {
                        conf: parseFloat(parts[5]),
                        x: parseFloat(parts[1]),
                        y: parseFloat(parts[2]),
                        w: parseFloat(parts[3]),
                        h: parseFloat(parts[4]),
                        visible: true
                    };
                    predictions.push(prediction);
                }
            }
            setPredictions(predictions);
            setLoaded(true);

        }
        fetchImages();
    }, []);

    React.useEffect(() => {
        // plot on canvas
        let canvas = document.getElementById('canvas-plot');
        if (!canvas) {
            return;
        }
        let ctx = canvas.getContext('2d');
        canvas.width = imgRef.width;
        canvas.height = imgRef.height;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        for (let i = 0; i < predictions.length; i++) {
            if (!predictions[i].visible) {
                continue;
            }
            // copy predictions
            let prediction = { ...predictions[i] };
            prediction.x = Math.round(prediction.x * imgRef.width) - Math.round(prediction.w * imgRef.width / 2);
            prediction.y = Math.round(prediction.y * imgRef.height) - Math.round(prediction.h * imgRef.height / 2);
            prediction.w = Math.round(prediction.w * imgRef.width);
            prediction.h = Math.round(prediction.h * imgRef.height);
            ctx.strokeRect(prediction.x, prediction.y, prediction.w, prediction.h);
        }
    }, [predictions]);


    if (!loggedIn) {
        navigate('/login');
    }

    let imgRef = React.useRef(null);

    return (
        <div className="viewer-root">
            <Header />
            <div className="body">
                <div className="options">
                    <table className="patient-details-table">
                        <tbody>
                            <tr>
                                <td className="patient-details-table-header" colSpan="2">Patient Details</td>
                                <td>{record_id.slice(0, 8)}...</td>
                            </tr>
                            <tr>
                                <td className="patient-details-table-header" colSpan="2">Patient ID:</td>
                                <td>{record.patient_id}</td>
                            </tr>
                            <tr>
                                <td className="patient-details-table-header" colSpan="2">Patient Name:</td>
                                <td>{record.patient_name}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        {predictions.map((prediction, index) => {
                            return (
                                <div key={index} className="prediction">
                                    <Typography variant="body1" component="div">
                                        {`Prediction ${index + 1}: ${prediction.conf.toFixed(2)}`}
                                    </Typography>
                                    <VisibilityOnOff
                                        key={index}
                                        name={`prediction-${index}`}
                                        visible={true}
                                        onVisibilityChange={(name, visible) => {
                                            let index = parseInt(name.split('-')[1]);
                                            let newPredictions = [...predictions];
                                            newPredictions[index].visible = visible;
                                            setPredictions(newPredictions);
                                        }}
                                    />
                                </div>
                            );
                        }
                        )}
                    </div>
                </div>
                {loaded ? (

                    <div className="container">

                        <div className="image-container">
                            <img
                                id="img"
                                ref={node => { imgRef = node }}
                                width="90%"
                                height="90%"
                                className="img"
                                src={imgSrc}
                                onWheel={(e) => {
                                    onChangeWheel(e);
                                }}
                            />
                            <canvas className="canvas-plot" id="canvas-plot"></canvas>
                        </div>
                    </div>

                ) : (
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress
                                variant="determinate"
                                color="secondary"
                                size={60}
                            >
                            </CircularProgress>
                        </Box>
                    </div>
                )
                }
            </div>
        </div>
    );
}